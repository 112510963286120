import { Suspense, useState } from "react";
import "./App.css";
import { IntlProvider } from "react-intl";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SharedLayout from "./pages/SharedLayout";
import { useSelector } from "react-redux";
import Home from "./pages/Home";
import Location from "./pages/Location";
import Util from "./pages/Util";
import Subdivision from "./pages/Subdivision";
import Gallery from "./pages/Gallery";

import LayoutWisteria from "./pages/wisteria/LayoutWisteria";
import HomeWisteria from "./pages/wisteria/HomeWisteria";
import EbrochureWisteria from "./pages/wisteria/EbrochureWisteria";
import GroundWisteria from "./pages/wisteria/GroundWisteria";
import HouseWisteria from "./pages/wisteria/HouseWisteria";
import UtilWisteria from "./pages/wisteria/UtilWisteria";

import Loading from "./pages/Loading";

import LayoutBinhMinh from "./pages/binhminh/LayoutBinhMinh";
import HomeBinhMinh from "./pages/binhminh/HomeBinhMinh";
import GroundBinhMinh from "./pages/binhminh/GroundBinhMinh";
import UtilBinhMinh from "./pages/binhminh/UtilBinhMinh";
import HouseBinhMinh from "./pages/binhminh/HouseBinhMinh";
import EbrochureBinhMinh from "./pages/binhminh/EbrochureBinhMinh";

import LayoutThaoVien from "./pages/thaovien/LayoutThaoVien";
import HomeThaoVien from "./pages/thaovien/HomeThaoVien";
import GroundThaoVien from "./pages/thaovien/GroundThaoVien";
import UtilThaoVien from "./pages/thaovien/UtilThaoVien";
import HouseThaoVien from "./pages/thaovien/HouseThaoVien";
import EbrochureThaoVien from "./pages/thaovien/EbrochureThaoVien";

import LayoutThinhVuong from "./pages/thinhvuong/LayoutThinhVuong";
import HomeThinhVuong from "./pages/thinhvuong/HomeThinhVuong";
import GroundThinhVuong from "./pages/thinhvuong/GroundThinhVuong";
import UtilThinhVuong from "./pages/thinhvuong/UtilThinhVuong";
import HouseThinhVuong from "./pages/thinhvuong/HouseThinhVuong";
import EbrochureThinhVuong from "./pages/thinhvuong/EbrochureThinhVuong";
import Verify from "./pages/Verify";

function App() {
  const { messages, locale } = useSelector((state) => state.language);
  const [checkVerify, setCheckVerify] = useState(true);

  return (
    <IntlProvider messages={messages} locale={locale}>
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <Router>
            {checkVerify === true ? (
              <Routes>
                <Route path="/" index element={<Loading />} />
                {/* <Route index path="/demo" element={<DemoMap />} />  */}
                <Route element={<SharedLayout />}>
                  <Route index path="/tour" element={<Home />} />
                  <Route index path="/locations" element={<Location />} />
                  <Route index path="/utilities" element={<Util />} />
                  <Route index path="/subdivisions" element={<Subdivision />} />
                  <Route index path="/gallery" element={<Gallery />} />
                </Route>

                <Route element={<LayoutWisteria />}>
                  <Route
                    index
                    path="/the-wisteria"
                    element={<HomeWisteria />}
                  />
                  <Route
                    index
                    path="/the-wisteria/ground"
                    element={<GroundWisteria />}
                  />
                  <Route
                    index
                    path="/the-wisteria/utilities"
                    element={<UtilWisteria />}
                  />
                  <Route
                    index
                    path="/the-wisteria/houses"
                    element={<HouseWisteria />}
                  />
                  <Route
                    index
                    path="/the-wisteria/ebrochure"
                    element={<EbrochureWisteria />}
                  />
                </Route>

                <Route element={<LayoutBinhMinh />}>
                  <Route index path="/binh-minh" element={<HomeBinhMinh />} />
                  <Route
                    index
                    path="/binh-minh/ground"
                    element={<GroundBinhMinh />}
                  />
                  <Route
                    index
                    path="/binh-minh/utilities"
                    element={<UtilBinhMinh />}
                  />
                  <Route
                    index
                    path="/binh-minh/houses"
                    element={<HouseBinhMinh />}
                  />
                  <Route
                    index
                    path="/binh-minh/ebrochure"
                    element={<EbrochureBinhMinh />}
                  />
                </Route>

                <Route element={<LayoutThaoVien />}>
                  <Route index path="/thao-vien" element={<HomeThaoVien />} />
                  <Route
                    index
                    path="/thao-vien/ground"
                    element={<GroundThaoVien />}
                  />
                  <Route
                    index
                    path="/thao-vien/utilities"
                    element={<UtilThaoVien />}
                  />
                  <Route
                    index
                    path="/thao-vien/houses"
                    element={<HouseThaoVien />}
                  />
                  <Route
                    index
                    path="/thao-vien/ebrochure"
                    element={<EbrochureThaoVien />}
                  />
                </Route>

                <Route element={<LayoutThinhVuong />}>
                  <Route
                    index
                    path="/thinh-vuong"
                    element={<HomeThinhVuong />}
                  />
                  <Route
                    index
                    path="/thinh-vuong/ground"
                    element={<GroundThinhVuong />}
                  />
                  <Route
                    index
                    path="/thinh-vuong/utilities"
                    element={<UtilThinhVuong />}
                  />
                  <Route
                    index
                    path="/thinh-vuong/houses"
                    element={<HouseThinhVuong />}
                  />
                  <Route
                    index
                    path="/thinh-vuong/ebrochure"
                    element={<EbrochureThinhVuong />}
                  />
                </Route>

                <Route path="*" element={() => <h1>Not found</h1>} />
                {/* <Route path="*" element={<Error />} /> */}
              </Routes>
            ) : (
              <Routes>
                <Route
                  path="/*"
                  element={<Verify setCheckVerify={setCheckVerify} />}
                />
              </Routes>
            )}
          </Router>
        </Suspense>
      </div>
    </IntlProvider>
  );
}

export default App;
